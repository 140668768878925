import type { RootState } from '../store';
import { RoleEnum } from 'utils/authentication/authentication-types';
import { Role } from 'utils/permissions/permission-types';

export const getIsAdministrator = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(({ role }: Role) => role === RoleEnum.ROLE_ADMIN);
};
export const getIsSurveyor = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(({ role }: Role) => role === RoleEnum.ROLE_SURVEYOR);
};
export const getIsLandowner = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(({ role }: Role) => role === RoleEnum.ROLE_LANDOWNER);
};
export const getIsMkhisUser = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(
    ({ role }: Role) =>
      role === RoleEnum.ROLE_VALUATOR || role === RoleEnum.ROLE_ADMIN_MASS_VALUATION
  );
};
export const getIsRegistrar = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(
    ({ role }: Role) =>
      role === RoleEnum.ROLE_CADASTER_REGISTRAR || role === RoleEnum.ROLE_ADMIN_CADASTER_REGISTRAR
  );
};

export const getHasOrganization = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.companies?.length > 0;
};

export const getIsRepresentative = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(({ role }: Role) => role === RoleEnum.ROLE_KOV_REPRESENTATIVE);
};

export const getIsKovRole = (state: RootState): boolean => {
  const { activeUser } = state.authentication;
  return activeUser.roles?.some(({ role }: Role) => (role === RoleEnum.ROLE_KOV_REPRESENTATIVE) || (role === RoleEnum.ROLE_KOV_CADASTER_REGISTRAR) || (role === RoleEnum.ROLE_KOV_OFFICIAL));
};